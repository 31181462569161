//
// Layout Config
//

// Root font Sizes
$root-font-size:                                    13px; // Root font size for desktop mode
$root-font-size-lg:                             	13px; // Root font size for tablet mode
$root-font-size-md:                             	12px; // Root font size for mobile mode

// Page bg
$page-bg: 											#f4f6fa !default;
$page-bg-dark:										#151521 !default;

// Content border radius
$content-border-radius:								1.5rem !default;

// Content Spacing
$content-spacing: (
	desktop: 30px, // Padding for desktop mode
	tablet-and-mobile: 15px // Padding for tablet and mobile modes
) !default;

// Header
$header-config: (
	// Default Mode
	default: (
		height: (
			desktop: 65px,
			tablet-and-mobile: 55px
		),
		bg-color: $white,
		bg-color-dark: #1E1E2D,
	),

	// Fixed Mode
	fixed: (
		height: (
			desktop: 65px,
			tablet-and-mobile: 55px
		),
		z-index: 100,
		bg-color: $white,
		bg-color-dark: #1E1E2D,
		box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05),
		box-shadow-dark: none
	)
) !default;

// Toolbar
$toolbar-config: (
	z-index: 99,
	height: (
		desktop: 75px,
		tablet-and-mobile: 60px
	),
	padding: 0.5rem 0,
	bg-color: $white,
	bg-color-dark: darken(#1e1e2d, 2%),
	box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05),
	box-shadow-dark: none,
	border-top: 1px solid $border-color,
	border-top-dark: 0
) !default;

// Aside
$aside-config: (
	z-index: 101, // Aside's z-index property
	transition-speed: 0.3s, // transition speed
	padding-x: 25px,
	menu-indention: 0.75rem,
	bg-color: $white,
	bg-color-dark: #1e1e2d,
	logo-bg-color: darken(#1e1e2d, 2%),
	scrollbar-color: #3b3b64,
	scrollbar-color-dark: $gray-200-dark,
	scrollbar-hover-color: lighten(#3b3b64, 3%),
	scrollbar-hover-color-dark: $gray-300-dark,
	width: 265px, // Aside width for desktop mode
	minimized-width: 75px,  // Aside minimized width for desktop mode
	box-shadow: 0 0 28px 0 rgba(82,63,105,.05),
	box-shadow-dark: none,
	minimized-hover-box-shadow: 5px 0px 10px rgba(70, 78, 95, 0.075),
	minimized-hover-box-shadow-dark: none
) !default;

// Footer
$footer-config: (	
	bg-color: $white,
	bg-color-dark: #1B1B28,
) !default;
