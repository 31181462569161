/* en pantalla */
@media screen {
	.sessionreport {
		max-width: 100%;		
		padding: 10px;
	}		
	.sessionreport .full-scores {
    	display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    background: #7e7e7e;
	    color: #fff;
	    padding: 5px;
	}
	.sessionreport .full-scores .fecha {
	    font-size: 0.8rem;
	    margin-bottom: 4px;
	}
	.sessionreport .full-scores .campo {
	    font-size: 0.8rem;
	    margin-top: 4px;
	}	
	.sessionreport .full-scores .result {
	    width: 100%;
	    display: flex;
	    height: 30px;
	    justify-content: center;
	}
    /* separador de sección */
    .sessionreport_section {
        margin: 2em 0;
        page-break-inside: avoid;
    }
    .sessionreport_section > h3 {
        margin-top: 4em;
        margin-bottom: 1em;
    }

    @media (max-width: 1200px) { /* small displays */
		.sessionreport_section {
			overflow: auto;
		}
	}
    
}

/* en impresión */
@media print {
	body {
		-webkit-print-color-adjust: exact !important;
	}
    html, body {
		width: 210mm;
		height: 297mm;
	}
    @page {
        size: A4;
        /*size: A4 landscape*/
        margin: 4mm;
    }
     /* separador de sección */
    .sessionreport_section {
        margin: 50px 0 !important;
        page-break-inside: avoid;
    }
    .page-kreak {
        page-break-inside: avoid;
    }
	.header, .content .toolbar {
		display: none !important;
	}	
    .header-fixed.toolbar-fixed .wrapper {
        padding-top: 0 !important;
    }
	.sessionreport {
		font-size: 0.65em;
	}	
	.full-scores {
    	display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    background: #7e7e7e;
	    color: #fff;
	    padding: 5px;
	}
	.full-scores .fecha {
	    font-size: 0.8rem;
	    margin-bottom: 4px;
	}
	.full-scores .campo {
	    font-size: 0.8rem;
	    margin-top: 4px;
	}	
	.full-scores .result {
	    width: 100%;
	    display: flex;
	    height: 30px;
	    justify-content: center;
	}	
    .sessionreport_timeline td:after {
		bottom: 32px;
	}
	.sessionreport_timeline_time {
		max-width: 48px;
	}
	.sessionreport_timeline_image img {
		height: 48px;
	}
	.sessionreport_playerwphoto_caption span {
		font-size: 8pt;
	}
}

/* partes */
.sessionreport_parts {
	display: flex;
	justify-content: center;
}
.sessionreport_parts>* {
	margin: 1px;
}

/* superíndices genéricos */
.sessionreport .sup_green,
.sessionreport .sup_red {
	vertical-align: super;
	font-size: 0.75em;
}
.sessionreport .sup_green {
	color: #192;
}
.sessionreport .sup_red {
	color: #C00;
}

/* título */
.sessionreport_title {
	display: flex;
	justify-content: space-between;
	font-size: 1.7em;
	border-left: 8px solid #666;
	background: #DDD;
	padding: 10px 16px;
}
.sessionreport_title b {
	font-weight: normal;
}
.sessionreport_title span {
	text-align: right;
	font-size: 1rem;
}
.sessionreport_title_squad_date {
	display: flex;
	flex-direction: column;
}
.sessionreport_title_squad {
	opacity: 0.7;
}
.sessionreport_title_date {
	font-weight: bold;
}

/* captions y valores */
.sessionreport_gcaption {
	color: #888;
	font-size: 0.8em;
}
.sessionreport_tvalue {
	font-size: 1.3em;
}

/* título equipos */
.sessionreport_shield {
	position: relative;
	min-width: 80px;
	min-height: 100px;
}
.sessionreport_shieldteam {
	display: flex;
	align-items: center;
	margin: 0 -10px;
}
.sessionreport_shieldteam>* {
	margin: 0 10px;
}
.sessionreport_goals {
	position: absolute;
	right: 0;
	bottom: 0;
	border: 1px solid #FFF;
	background: #444;
	color: #FFF;
	font-size: 1.3em;
	line-height: 1em;
	padding: 6px;
	font-weight: bold;
}
.sessionreport_tteams {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px -10px;
}
.sessionreport_tteams>* {
	margin: 0 10px;
}
.sessionreport_tteams img {
	height: 100px;
}

/* tabla separadora por celdas */
.sessionreport_scells {
	width: 100%;
	border-spacing: 10px;
}
.sessionreport_scell {
	background: #EEE;
	padding: 1.8em 1em;
	border: 2px solid #FFF;
}
.sessionreport_scell_caption {
	color: #999;
}
.sessionreport_scell_value {
	text-align: center;
}
.sessionreport_scell_value b {
	font-size: 2.5em;
}

/* línea de tiempo */
.sessionreport_timeline_mar {
	margin: 10px 12px;
}
.sessionreport_timeline table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
.sessionreport_timeline td {
	position: relative;
	vertical-align: bottom;
	padding: 0;
}
.sessionreport_timeline td:after {
	position: absolute;
	bottom: 44px;
	left: 0;
	right: 0;
	font-size: 1px;
	display: block;
	content: " ";
	border-bottom: 2px solid #222;
}
.sessionreport_timeline_cell {
	position: relative;
	z-index: 1;
}
.sessionreport_timeline_caption {
	white-space: nowrap;
	transform: rotate(-45deg);
	margin: 60% 20% 25% 20%;
}
.sessionreport_timeline_time {
	font-size: 0.7em;
	text-align: center;
	max-width: 64px;
	color: #666;
}
.sessionreport_timeline_image img {
	display: inline-block;
	border: 0;
	margin: 0;
	width: 64px;
	height: 64px;
}
.sessionreport_timeline_flag {
	width: 0;
	background: #FFF;
}
.sessionreport_timeline_flag .sessionreport_timeline_time {
	max-width: initial;
}
.sessionreport_timeline_flag .sessionreport_timeline_image img {
	background: #FFF;
	width: 32px;
	padding: 16px 0;
}
.sessionreport_timeline_flag_end .sessionreport_timeline_cell {
	float: right;
}
/* jugador con foto */
.sessionreport_playerwphoto {
	display: flex;
	align-items: center;
}
.sessionreport_playerwphoto_photo img {
	height: 2.2em;
}
.sessionreport_playerwphoto_photo {
	display: flex;
}
.sessionreport_playerwphoto_caption {
	margin-left: 10px;
	flex: 1;
}
.sessionreport_playerwphoto_caption span {
	font-size: .8rem;
}
.player-separator {
	height: 50px;
}
/* tabla genérica */
.sessionreport_table {
	width: 100%;
	border-collapse: separate;
}
.sessionreport_table>thead>tr>th:not(:empty) {
	color: #FFF;
	background: #000;
}
.sessionreport_table>thead>tr>th,
.sessionreport_table>tbody>tr>td {
	padding: 0.6em;
}
.sessionreport_table_rowed>*>tr>*.sessionreport_table_gc {
	padding-left: 0.3em;
	padding-right: 0.3em;
}
.sessionreport_table_rowed>tbody>tr:nth-child(even)>td.sessionreport_table_cell,
.sessionreport_table_gray>tbody>tr>td {
	background-color: #EEE;
}
.sessionreport_graphs {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 50px 0;
}
.sessionreport_graphs .sessionreport_heatmap {
	max-width: 50%;
	min-width: 50%;
	display: flex;
	justify-content: center;
}
.sessionreport_field {
	/*background-image: url('images/field.jpeg');*/
	background-size: 350px 217px;
	overflow: hidden;
	max-height: 217px;
    height: 217px;
    width: 350px;
    max-width: 350px;
}
.float-left { 
	float: left; 
}
.float-right { 
	float: right; 
}
.clear { 
	clear: both; 
}
.border-bottom {
	border-bottom: 2px solid #fff !important;
}
.align-center {
	text-align: center;
}
.align-left {
	text-align: left;
}
.major-green {
	background-color: #2B4 !important;
}
.major-distance-1 {
	background-color: #2B4 !important;
}
.major-distance-2 {
	background-color: rgba(34, 187, 67, 0.75) !important;
}
.major-distance-3 {
	background-color: rgba(34, 187, 67, 0.5) !important;
}
.major-speed-1 {
	background-color: rgba(255, 199, 0, 1) !important;
}
.major-speed-2 {
	background-color: rgba(255, 199, 0, 0.75) !important;
}
.major-speed-3 {
	background-color: rgba(255, 199, 0, 0.5) !important;
}
