//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base";
@import "layout/layout";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

// Initialize
@import "report";
body {
    background: #f4f6fa !important;
}
.header {
    padding: 0 1rem !important;
}
.content {
    padding: 0;
}
.content-gray {
    background: #f4f6fa;
}
.bg-desa, .badge-desa {
    background-color: #0302d2 !important;
}
.bg-ubiko, .bg-ubiko-active:hover, .bg-hover-ubiko:hover, .badge-ubiko {
    background: #98ff65 !important;
}
.btn.btn-dark.bg-hover-ubiko:hover {
    background: #98ff65 !important;
    color: var(--kt-dark);
}
.bg-ubiko-light, .bg-hover-ubiko-light:hover {
    background: #98ff653b !important;
}
.bg-highlight, table .bg-highlight button, table .bg-highlight .symbol-label {
    background: #fffce5 !important;
}
.text-ubiko, .text-hover-ubiko:hover {
    color: #98ff65 !important;
}
.svg-icon.svg-icon-ubiko {
    color: #98ff65;
}
.btn.btn-dark .svg-icon {
    color: #98ff65 !important;
}
.btn-ubiko {
    background: #98ff65 !important;
}
.btn-ubiko:hover {
    background: #85eb53 !important;
}
.btn-gray-700 {
    background: var(--kt-text-gray-700) !important;
}
.btn-gray-700 i, .btn-gray-700, .btn-gray-700 span {
    color: #fff;
}
.btn-gray-700:hover {
    background: var(--kt-text-gray-800) !important;
}
.btn-ubiko, .btn-ubiko .svg-icon {
    color: #000 !important;
}
.align-center {
    text-align: center;
}
.container-xxxl {
    padding-right: 2rem;
    padding-left: 2rem;
    max-width: calc(100% - 4rem);
    margin: 0 auto;
}
.outline-0 {
    outline: none !important;
}
.h-min-35px {
    min-height: 35px;
}
.h-min-37px {
    min-height: 37px;
}
.zindex-9 {
    z-index: 9;
}
.row-cards {
    margin: 0 -1rem 0 -1rem;
    max-width: 100%;
    width: 100%;
}
.row-cards > .card {
    margin: .5rem;
}
.row-cards > .col-lg-12 {
    width: calc(100% + 1rem);
}
.aside-enabled.aside-fixed .wrapper {
    padding-left: 0 !important;
}
.aside-enabled.aside-fixed.header-fixed .header,
.aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    left: 0 !important;
    overflow: visible;
}
.menu-state-title-primary .menu-item .menu-link.active .svg-icon,
.menu-state-title-primary .menu-item .menu-link.active i {
    color: var(--kt-text-dark);
}
.header-logo-left {
    margin-left: 2rem;
}
.login-auth {
    width: 30vw;
    height: 100vh;
    background: #000 !important;
    min-width: 550px;
}
.login-logo {
    max-height: 40px;
}
.w-48 {
    width: 48px;
}
.toolbar-fixed .toolbar {
    z-index: 97 !important;
}
.modal-overlay, .global-overlay, .table-overlay {
    background: #ffffffb3;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-overlay.solid {
    background: #fff;
}
.modal-overlay, .table-overlay {
    position: absolute;
    height: 100%;
}
.global-overlay {
    position: sticky;
    height: 100vh;
}
.table-overlay {
    border-radius: var(--bs-card-border-radius);
    position: absolute;    
    height: 100%;
}
.modal-overlay .logo, .global-overlay .logo, .table-overlay .logo {
    margin-top: -3.25rem;
}
.modal-overlay .logo img, .global-overlay .logo img, .table-overlay .logo img {
    max-width: 50px;
}
.global-overlay, .table-overlay {
    z-index: 98;
    width: 100%;
}
.indicator-progress {
    display: inline-block !important;
}
.global-overlay {
    top: 0 !important;
    position: fixed !important;
    height: 100vh;
    background: #ffffffd4 !important;
    z-index: 105;
}
.footer {
    height: 65px;
}
.modal-footer {  
    display: none !important;
}
.modal-custom-footer {    
    border-top: 1px solid var(--bs-modal-footer-border-color);
    margin-left: -1.7rem;
    margin-right: -1.75rem;
    padding: 1.75rem;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;   
    margin-top: 2rem;
}
.container-xxl.w-100 {
    max-width: 100%;
}
.background-masker {
    background-color: #fff;
    position: absolute;
}
@keyframes overlayLoop {
    0% {
        transform: translate(0, 50px);
    }
    100% {
        transform: translate(0, -50px);
    }
}
.modal-overlay .logo:before, .global-overlay .logo:before {    
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background: #ffffff85;
    top: 0;
    left: 0;
    animation: overlayLoop 3s ease-in-out infinite;
    animation-direction: alternate;
}
.modal-overlay .logo, .global-overlay .logo { 
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}
.page-header .icon img {
    max-height: 40px;
}
.copyright {
    margin-left: 2rem;
}

/* Navs */
.nav .show > .nav-link .nav-text, .nav .nav-link:hover:not(.disabled) .nav-text, .nav .nav-link.active .nav-text {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #3699FF;
}
.nav .nav-link .nav-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 1rem;
    color: #7E8299;
    font-weight: 400;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}
.nav-icon {
    margin-right: 10px;
}
.nav.nav-pills .show > .nav-link, .nav.nav-pills .nav-link.active {
    color: #ffffff;
    background-color: #3699FF;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}
.nav.nav-dark .show > .nav-link .nav-text, .nav.nav-dark .nav-link:hover:not(.disabled) .nav-text, .nav.nav-dark .nav-link.active .nav-text {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #000;
}

/* Alerts */
.alert {
    font-size: 1rem !important;
}
.alert.alert-custom.alert-default {
    background-color: #F3F6F9;
    border-color: #F3F6F9;
}
.alert.alert-custom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.0rem 2rem;
}
.alert.alert-custom .alert-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1.25rem 0 0;
}
.svg-icon.svg-icon-xl svg {
    height: 2rem !important;
    width: 2rem !important;
}

/* Forms */
.btn {
    font-size: 1rem !important;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm, 
.btn-group-sm > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: calc(0.5rem + 1px) calc(1.25rem + 1px) !important;
}
.form-control.form-select {
    padding-right: 3rem !important;
}
.form-control, .input-group-text {
    padding: 0.5rem 1rem !important;
}
.col-form-label {
    padding-top: calc(0.5rem + 1px) !important;
    padding-bottom: calc(0.5rem + 1px) !important;    
}
.form-control.ngb-tp-input {
    padding: 0.5rem !important;
    width: 36px;
}
.form-check-input:checked {
    background-color: #181c32 !important;
    border-color: #181c32 !important;
}
.ngb-tp-input-container {
    width: 48px !important;
}
.progress {
    font-size: 1rem !important;
}
.form-control, .form-select {
    font-size: 1rem !important;
}
.rounded-left, .rounded-0.rounded-left {
    border-radius: .475rem 0 0 .475rem !important;
}
.rounded-right, .rounded-0.rounded-right {
    border-radius:  0 .475rem .475rem 0 !important;
}
.form-check-input[type=radio] {
    width: 28px;
    height: 28px;
}
button .svg-icon-primary {
    font-size: 1.25rem;
}
#table-complete-search, app-datatable .card-toolbar .btn {
    height: 38px;
}
.session-score {
    padding: 10px;
    height: calc(1.5em + 1.4rem + 2px);
    border-radius: 0.425rem;
    display: flex;
    align-items: center;
    position: relative;
}
.session-score .score-goals {
    font-weight: 800;
    margin: 0 1rem;
    min-width: 20px;
    text-align: center;
}
.session-score .score-team {
    min-width: 100px;
    display: inline-block;
}
.session-score-field {
    background: url('/assets/images/field.jpeg');
    background-size: 100%;
    position: relative;
}
.session-score-field:after {
    content: "";
    width: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 0.475rem;
    background: #00000080;
    z-index: 1;
}
.session-score-field div {
    z-index: 9;
}
.session-score .local:after {
    opacity: 1;
    position: absolute;
    z-index: 5;
    content: "";
    left: -5px;
    top: 0;
    height: 12px;
    width: 12px;
    background-color: inherit;
    transform: skewX(-45deg);
    background: var(--kt-secondary);
}
.session-score.lost .local:after {
    background: var(--kt-text-danger);
}
.session-score.win .local:after {
    background: #98ff65;
}
.heatmap {
    max-height: 350px;
    padding: 5px;
    max-width: calc((100% / 4) - 50px);
}
.heatmap .heatmap-container {
    padding: 3px;
}
.heatmap img {
    z-index: 2;
    position: relative;
    max-height: 100%;
    max-width: 100%;
}
.heatmap-field {
    background: url('/assets/images/field.jpeg');
    background-size: 100% 100%;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
}
.section-line-top {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--kt-border-color);
}
.menu-state-title-primary .menu-item .menu-link.active,
.menu-state-title-primary .menu-item .menu-link.active .menu-title {
    color: var(--kt-text-gray-700);
}
.badge-status {
    position: relative;  
}
.badge-status span {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: block;      
}
.menu .menu-item {
    position: relative;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
}
.menu .menu-item.new-session {
    white-space: nowrap;
}
.menu .menu-item .bullet, .bullet-round {
    top: 5px;
    left: 25px;
    width: 12px;
    height: 12px;
}
.progress-icon .bullet-round {
    top: -5px;
    left: 15px;
}
.session-part {
    background-position: right top; 
    background-size: 30% auto; 
    background-image: url('/assets/media/svg/shapes/abstract-2.svg');
}
.player-list {
    border-bottom-color: var(--kt-gray-300);
    border-bottom-width: 1px;
    border-bottom-style: dashed;
}
.mvp small {
    font-size: .75rem
}
.grid-cards.flex-col-2 {
    flex-direction: row;
    flex-wrap: wrap;
}
.grid-cards.flex-col-2 .card {
    flex-basis: 50%;
    flex-grow: 1;
}
.grid-cards.flex-col-2.gap-4 .card {
    flex-basis: calc(50% - 2rem);
    flex-grow: 1;
}
.btn.btn-primary .svg-icon-dark {
    color: var(--kt-text-dark);
}
body#kt_body.swal2-height-auto {
    height: 100% !important;
}
.progress-bar {
    transition: width 2s;
}
.progress-bar.bg-success i, .progress-bar.bg-success span, .progress-bar.bg-success strong {
    color: var(--kt-text-dark) !important;
}
.progress-bar i, .progress-bar span, .progress-bar strong {
    color: var(--kt-text-dark) !important;
}
.progress-bar i.text-muted {
    color: var(--kt-text-muted) !important;
}
.progress-bar i.text-success {
    color: var(--kt-text-success) !important;
}
.progress-bar i.text-danger {
    color: var(--kt-text-danger) !important;
}
.progress-bar i.text-ubiko {
    color: #98ff65 !important;
}
.progress-bar .bg-success {
    background-color: var(--kt-text-success) !important;
}
.progress-bar .bg-danger {
    background-color: var(--kt-text-danger) !important;
}
.progress-bar .bg-ubiko, .bullet.bg-ubiko {
    background-color: #98ff65 !important;
}
button.btn-primary span, button.btn-primary .svg-icon,
a.btn-primary span, address.btn-primary .svg-icon {
    color: #000 !important
}
.ngb-tp-input-container .btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.select-slim {
    height: 30px;
}
.progressbar-content {
    width: calc(100% - 2rem);
}
.progressbar-content.position-absolute {
    left: 0;
}
.menu-state-title-primary .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-title, .menu-state-title-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
    color: var(--kt-dark);
}
.ngb-tp-chevron:before {
    color: #000;
}
.swal2-styled.swal2-confirm {
    background-color: #000000 !important;
}
.swal2-styled {
    box-shadow: none !important;
}
.session-scores input {
    min-width: 60px !important;
}
.input-group-prepend .input-group-text {
    border-radius: 0.475rem 0 0 0.475rem;
}
.input-group-text {
    font-size: 1rem !important;
}
.modal-xxl {
    --bs-modal-width: calc(100vw - 10vw) !important;
}
.modal-l {
    --bs-modal-width: calc(650px) !important;
}
.modal-xxl.not-header .modal-header {
    display: none
}
.table td.new {
    background: red;
}
.range-selector {
    height: 150px;
    width: 0;
    position: absolute;
    background: #0000001c;
    left: 50px;
    top: 0px;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    touch-action: none;
    box-sizing: border-box;
    user-select: none;
}
.global-chart .range-selector {   
    background: rgba(3, 169, 244, 0.11);
    border-left: 1px solid #2196f3;
    border-right: 1px solid #2196f3;
}
.slds-p-top--medium .device-selector-div {
    background: #0000005e;
    width: 24px;
    height: 24px;
    color: #fff;
    top: 63px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
}
.range-selector .range-title {
    position: relative;
    top: -25px;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -1px;
    margin-right: -1px;
    width: calc(100% + 2px);
    font-size: 1rem;
    padding: 0 10px;
    border: 1px dashed #d5d5d5;
    border-bottom: none;
    background: #fff;
}
.global-chart .range-selector .range-title {
    top: -30px;
    height: 30px;
}
.range-sparkline.range-selector {
    top: -50px;
    margin-top: 50px
}
.range-sparkline.range-selector .range-title {
    transform: translate(-50%, -10px);
    box-shadow: 0 0 15px rgba(0,0,0,.15);
    background: #444;
    top: -36px;
    height: auto;
    border: none;
    color: #fff !important;
    font-size: .9rem !important;
    max-width: unset;
    display: none !important;
    padding: 3px 10px;
    border-radius: 4px !important;
    left: 50%;
    position: absolute !important;
    width: unset !important;
    z-index: 9;
}
.range-sparkline.range-selector.active .range-title {
    display: inline-block !important;
}
.range-sparkline.range-selector .range-title:after {
    transform: translate(-50%, -10px) rotate(45deg);
    background: #444;
    position: absolute;
    z-index: 98;
    bottom: 0;
    left: 50%;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-bottom: -4px;
    transform: translate(-50%, 0) rotate(45deg);
    pointer-events: none;
    display: block;
}
.range-sparkline.range-selector .part-remove-icon {
    position: absolute;
    background: transparent;
    bottom: 0px;
}
.range-sparkline.range-selector .part-remove-icon .svg-icon.svg-icon-dark {
    color: #fff !important;
}
.range-sparkline.range-selector .part-duration {
    min-width: unset !important;
    display: block;
}
.page-top-sidebar {
    flex-grow: 1;
    flex-shrink: 0;
}
.page-top-sidebar.w-100.d-flex > div {
    flex-grow: 1;
}
.page-top-sidebar .card-sidebar {
    max-width: 370px;
    min-width: 370px;
}
.page-top-sidebar .stepper-desc {
    display: none;
}
.bb-dashed {
    border-bottom: 1px dashed #E4E6EF;
}
.part-remove-icon { 
    position: absolute;
    padding-right: 10px;
    right: 0;
    background: #fff;
}
.part-remove-icon a {    
    height: 24px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f44336 !important;
    font-size: 16px; 
    font-weight: 600;  
    cursor: pointer; 
}
.ellipsis {
    text-overflow: ellipsis;    
    overflow: hidden;
    white-space: nowrap;
}
.part-start, .part-end {
    font-weight: 600;
}
.range-selector .part-start:hover, .range-selector .part-end:hover  {
    z-index: 10;
    background: #035495 !important;
}
.range-sparkline .part-name {
    display: none;
}
.range-sparkline .part-start, .range-sparkline .part-end {
    font-size: 10px;
    font-weight: 500;
    transform: rotate(-90deg);
    height: 20px !important;
    padding: 0 !important;
    border-radius: 12px !important;
    height: 15px !important;
    top: 12px !important;
    width: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.range-sparkline .part-start {   
    left: -18px !important;
}
.range-sparkline .part-end {   
    right: -18px !important;
}
.part-duration-div {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -22px;
    left: 0;
}
.part-duration {
    min-width: 90px;
    font-weight: 600;
    padding-right: 50px;
}
.field-date {
    min-width: 120px;
    max-width: 120px;
}
.field-loading select {
    max-width: calc(100% - 30px);
}
.form-select .indicator-progress.position-absolute {
    right: -15px;
    top: 25px;
}
.range-selector .part-end {
    width: 45px;
    position: absolute;
    height: 20px;
    background: gray;
    top: 0;
    right: 0;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 8px 0 0 8px;
    padding-left: 4px;
}
.range-selector .part-start {
    width: 45px;
    position: absolute;
    height: 20px;
    background: gray;
    top: 0;
    left: 0;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 0 8px 8px 0;
    padding-right: 4px;
}
.axis-selector {
    background: white;
    position: absolute;
    width: 12px;
    height: 20px;
    top: calc(50% - 10px);
    border-radius: 4px;
    border: 1px solid #222;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.global-chart .axis-selector {
    border: 1px solid #2196f3;
}
.global-axis-color {
    color: #2196f3;
}
.axis-selector.right {
    right: -6px;
}
.axis-selector.left {
    left: -6px;
}
.global-chart .range-selector .part-start, .global-chart .range-selector .part-end {    
    background: #2196f3;
}
.global-chart .range-selector:after, .global-chart .range-selector:before {
    background: #000;
}
#kt_aside_mobile_toggle {
    display: none !important;
}
.card .card-header {
    min-height: 55px !important;
}
.card .card-header h3 {
    font-size: 1.15rem !important;
}
.card-filters-inner .bullet {
    background-color: #dbdbdb !important;
}

@media (max-width: 1720px) {
    .cycles .table {
        table-layout: fixed;
    }
}

@media (max-width: 1400px) {
    .team-quick-link {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 1200px) {
    .login-logo {
        max-height: 30px;
    }
    .header-logo-left {
        min-height: 50px;
    }
    .row-cards {
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
    }
    .row-cards .card {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
    }
    .flex-row-sm, .flex-column.flex-row-sm {
        flex-direction: row !important;
    }
    .absolute-sm {
        position: absolute !important;
    }
    .team-actions {
        top: -40px;
        right: 0;
    }
    .absolute-sm.team-actions {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .d-sm-none, .d-flex.d-sm-none {
        display: none !important;
    }
    .d-sm-block, .d-flex.d-sm-block {
        display: block !important;
    }
    .card-search-toolbar {
        display: block;
        width: 100%;
    }
    .menu-item {
        width: 90%;
    }
    .card-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .my-sm-4 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .mt-sm-4 {
        margin-top: 1rem;
    }
    .rounded.card-filters-inner {
        background: transparent !important;
    }
    .h-sm-auto {
        height: auto !important;
    }
    .h-sm-auto.card-filters-inner {
        height: auto !important;
    }
    .h-sm-50px, .h-sm-50px.h-75px {
        height: 50px !important;
    }
    .container-xxxl {
        padding-right: 1rem;
        padding-left: 1rem;
        max-width: calc(100%);
        margin: 0 auto;
    }
    .table tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .table thead {
        display: none;
    }
    .table tr td {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        justify-content: space-between;
        align-items: center;
        line-height: 22px;
    }
    .form-group .w-50 {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .modal-custom-footer {
        flex-direction: column;        
    }
    .modal-custom-footer button, .modal-custom-footer a {
        margin-bottom: 10px;   
        width: 100%;
    }
    .form-group .col-lg-3 {
        margin: 6px 0;
    }
    .table td label.d-sm-block {
        margin-right: 1rem;
    }
    .mx-sm-2 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .card-search-toolbar .h-40px .svg-icon.position-absolute,
    .card-search-toolbar .h-40px .position-absolute .spinner-border {
        top: 20px !important;
    }
    .form-control.form-select {
        min-height: 38px;
    }
    .table td.type-svg, .table td.type-image {
        display: none;
    }    
    .table-filter {
        margin-top: 10px;
    }
    .page-header-actions button label {
        display: none;
    }
    .page-header-actions button i {
        padding-right: 0;
    }
    .page-header .page-header-actions .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm {
        padding: calc(0.5rem + 1px) calc(.5rem + 1px) !important;
    }
    .page-header-actions .colors {
        width: 25px !important;
        margin-left: 1rem !important;
    }
    .header-has-actions {
        margin-top: 20px;
    }
    .flex-sm-column {
        flex-direction: column;
    }
    .card .card-header {
        min-height: unset;
    }    
    .stepper.stepper-pills.stepper-row .stepper-line {
        display: none !important;
    }
    .card-toolbar .week-days {
        display: none;
    }
    .form-group.w-100.d-flex {
        display: block !important;
    }
    .toolbar .breadcrumb {
        display: none !important;
    }
    td.table-actions, td.text-end {
        display: flex !important;
        justify-content: flex-end !important;
    }
    .select-kit {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .select-kit > a {
        width: 100%;
        margin-bottom: 25px;
    }
    .select-kit > a > div {
        width: 50%;
        text-align: center;
    }
    .card .card-header {
        justify-content: center;
    }
    .stepper-nav {
        flex-direction: column;
        align-items: center;
    }
    .stepper.stepper-pills .stepper-item {
        margin-bottom: 5px;
    }
    .container.drag-drop-container {
        width: 100%;
        min-width: 100% !important;
        max-width: 100%;;
    }
    .card-toolbar.week-days {
        display: none !important;
    }
    .custom-range > div {
        flex-direction: column;
    }  
    .team-quick-link {
        flex-direction: row;
        gap: 10px;
    } 
    .cycles-view-toggle {
        bottom: 50px !important;
    }
    .col-chart {
        justify-content: flex-end !important;
    }
}
@media (max-width: 900px) { 
    .login-logo {
        max-height: 25px;
    }
    .login-auth {
        width: 100%;
        height: 100vh;
        background: #0000009e !important;
        min-width: 100%;
    }
}

.device-chart, .global-chart {
    margin: 50px 0 0 0;
    max-width: 1250px;
}
.quiz-chartTip {
    padding: 5px 10px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 4px;
    background-color: rgba(255,255,255,.9);
    box-shadow: 3px 3px 10px rgba(0,0,0,.1);
    position: absolute;
    z-index: 50;
    max-width: 250px;
}
.quiz-graph {
    padding: 0; 
    height: 230px;
    width: 100%;
    overflow: visible !important;
}
.global-graph {
    padding: 0; 
    height: 230px;
    width: 100%;
    overflow: visible !important;
}
.quiz-graph .x-labels, .global-graph .x-labels {
    text-anchor: middle;
}
.quiz-graph .y-labels, .global-graph .y-labels {
    text-anchor: end;
}
.quiz-graph .quiz-graph-grid, .global-graph .quiz-graph-grid {
    stroke: #ccc;
    stroke-dasharray: 0;
    stroke-width: 1;
}
.label-title {
    text-anchor: middle;
    text-transform: uppercase;
    font-size: 12px;
    fill: gray;
}
.quiz-graph-dot, .quiz-graph-start-dot{
    fill: rgba(0,112,210,1);
    stroke-width: 2;
    stroke: white;   
}

/* Wizard */
.stepper.stepper-pills.stepper-column .stepper-nav {
    flex-direction: column;
    align-items: start;
}
.stepper.stepper-pills .stepper-nav, .stepper.stepper-links .stepper-nav {
    display: flex;
    position: relative;
    z-index: 9;
}
.stepper.stepper-pills.stepper-column .stepper-item {
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    padding: 0;
    margin: 0;
}
.stepper.stepper-pills .stepper-item.current {
    transition: color 0.2s ease;
}
.stepper.stepper-pills .stepper-item {
    display: flex;
    align-items: center;
    transition: color 0.2s ease;
    max-height: 65px;
}
.stepper.stepper-pills.stepper-column .stepper-wrapper {
    display: flex;
    align-items: center;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon {
    transition: color 0.2s ease;
    background-color: #181C32 !important;
    border: 0;
}
.stepper.stepper-pills .stepper-item.current .stepper-title a {
    color: #181C32 !important;
}.stepper.stepper-pills .stepper-item.current .stepper-wrapper {
    background: #f5f6fa6b;
}

.stepper.stepper-pills.stepper-column .stepper-icon {
    z-index: 1;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon .svg-icon {
    color: #98ff65;
}
.stepper.stepper-pills .stepper-item.current .bi,
.stepper.stepper-pills .stepper-item.current .fas {
    color: #98ff65;
}
.stepper.stepper-pills .stepper-item .stepper-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: color 0.2s ease;
    width: 40px;
    height: 40px;
    border-radius: 9px;
    background-color: #eff2f5;
    border: 0;
    margin-right: 1.5rem;
}
.stepper.stepper-pills .stepper-item.current:last-child .stepper-icon {
    background-color: #181C32;
}
.stepper.stepper-pills .stepper-item .stepper-icon .stepper-check {   
    font-size: 1rem;
}
.stepper.stepper-pills .stepper-item.current.mark-completed:last-child .stepper-icon .stepper-number, .stepper.stepper-pills .stepper-item.completed .stepper-icon .stepper-number {
    display: none;
}
.stepper.stepper-pills .stepper-item .stepper-icon .stepper-number, .stepper.stepper-pills .stepper-item .bi,
.stepper.stepper-pills .stepper-item .stepper-icon .stepper-number, .stepper.stepper-pills .stepper-item .fas {
    font-weight: 600;
    color: var(--kt-dark);
    font-size: 1.25rem;
    line-height: unset !important;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon .stepper-number {
    color: #98ff65; /*var(--kt-primary);*/
}
.stepper.stepper-pills .stepper-item .stepper-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.stepper.stepper-pills.stepper-row .stepper-line {
    display: block;
    flex-grow: 1;
    margin: 0 5px;
    border-top: 1px dashed #E4E6EF; 
}
.edit .stepper.stepper-pills.stepper-row .stepper-line {   
    display: none;
}
.h-40px {
    height: 40px !important;
}
.stepper.stepper-pills .stepper-item .stepper-label .stepper-title {
    color: #fff !important;
}
.modal-stteper {
    position: relative;
    margin-left: calc(var(--bs-modal-padding) * -1);
    margin-right: calc(var(--bs-modal-padding) * -1);
    margin-top: calc(var(--bs-modal-padding) * -1);
    background-color: var(--kt-dark);
}
.stepper.modal-stteper.stepper-links .stepper-nav .stepper-item {
    margin: 0 !important;
    padding: 1rem 1.5rem;
}
.stepper.modal-stteper.stepper-links .stepper-nav .stepper-item:after {
    top: unset !important;
    bottom: 0;
}
.stepper.modal-stteper.stepper-links .stepper-nav .stepper-item:after {
    height: 6px !important;
}
.stepper.modal-stteper.stepper-links .stepper-nav .stepper-item.current:after,
.stepper.modal-stteper.stepper-links .stepper-nav .stepper-item:hover:after {
    background-color: #98ff65 !important;
}
.stepper.modal-stteper.stepper-links .stepper-nav .stepper-item .stepper-title {
    color: #fff !important;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}
.session-edit .stepper:after, .fieldwiz-wizard .stepper:after, .modal-stteper:after {
    content: "";
    width: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAAXNSR0IArs4c6QAAAB5JREFUGFdjZGBgaAdiEKhghHIqgHQHiPMfxABJAQBGqwQRpMIDkwAAAABJRU5ErkJggg==);
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    left: 0;
    opacity: 0.3;
}
.topbar-alerts > h3, .topbar-alerts ul {
    position: relative;
    z-index: 9;
}
.stepper-wrapper.cursor-pointer:hover .stepper-icon {
    background: var(--kt-dark) !important;
}
.stepper-wrapper.cursor-pointer:hover .stepper-icon i {
    color: #98ff65 !important;
}
.stepper-wrapper.cursor-pointer:hover .stepper-title a {
    color: var(--kt-dark) !important;
}
.stepper-wrapper.cursor-pointer:hover {
    background: #f5f6fa6b;
}
.stepper-wrapper.cursor-pointer .stepper-title a {
    transition: none !important;
}
.edit .stepper-title .flex-column {
    flex-direction: row !important;
}
.edit .stepper-title .flex-column small {
    font-size: 1.35rem !important;
    font-weight: 700 !important;
    margin-right: 5px;
}
.device-header {
    z-index: 2;
    min-height: 35px;
}
.card-toolbar .nav-link:hover {
    border-bottom: 1px solid var(--kt-dark);
}
.range-lines {
    display: none;
}
.range-lines.show {
    display: block;
}
.range-lines:before {
    position: absolute;
    content: "";
    width: 1px inherit;
    width: 0px;
    height: 400px;
    border: 1px dashed #ededed;
    left: -1px;
    z-index: 0;
}
.range-lines:after {
    position: absolute;
    content: "";
    width: 1px inherit;
    width: 0px;
    height: 400px;
    border: 1px dashed #ededed;
    right: -1px;
    z-index: 0;
    top: 50px;
}

/* Timeline */
.timeline .timeline-item {
    left: calc(50% - 20px);  
    align-items: center; 
    height: 50px;
    max-width: 50%;
}
.timeline .timeline-item .move-left {
    position: absolute;
    width: 100%;
    left: -100%;
    text-align: right;
    padding-right: 15px !important;
    display: flex;
    justify-content: flex-end;
}
.timeline .timeline-item .move-left {
    position: absolute;
    width: 100%;
    left: -100%;
    text-align: right;
    padding-right: 15px !important;
    display: flex;
    justify-content: flex-end;
}
.timeline .timeline-item .move-left.pe-0 {
    padding-right: 0 !important;
}
.timeline .timeline-content {
    width: auto
}
.timeline .timeline-line {
    border-left-color: var(--kt-secondary) !important;
}
.timeline .timeline-item .timeline-time-actions {
    display: flex;
    align-items: center;
}
.timeline .timeline-item .move-left .timeline-time-actions {
    flex-direction: row-reverse;
}
.timeline .timeline-item .timeline-actions {
    margin-left: 2rem;
}
.timeline .timeline-item .move-left .timeline-actions {
    margin-right: 2rem;
}
.timeline .timeline-item:hover .symbol-label {
    background: #E4E6EF !important;
}
.card-footer-fixed {   
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
    background: #fff !important;
    left: 0;
    border-top: 1px solid #eff2f5;
}
.select-spinner-inner {
    right: 40px;
}
.form-change-team {
    background-color: #3e414e !important;
}
.page-link.active, .active > .page-link {
    background-color: #000000 !important;
}

/* DARK THEME */
html[data-theme='dark'] .menu-state-title-primary .menu-item .menu-link.active, 
html[data-theme='dark'] .menu-state-title-primary .menu-item .menu-link.active .menu-title,
html[data-theme='dark'] .menu-item .menu-link .menu-title,
html[data-theme='dark'] .table td .text-dark,
html[data-theme='dark'] .form-select {
    color: #fff !important;
}
html[data-theme='dark'] .form-select {
    background: #3e414e !important;
    color: #fff !important;
    border-color: #3e414e;
}
html[data-theme='dark'] .header-fixed .header {
    background-color: #2b2b40 !important;
}
html[data-theme='dark'] body {
    background: #2b2b40 !important;
}
html[data-theme='dark'] .card {
    background: #181c32;
    border-color: #181c32;
    color: #fff;
}
html[data-theme='dark'] .card .bi.text-dark {
    color: #98ff65 !important;
}
html[data-theme='dark'] .btn-dark {
    background: #98ff65 !important;
    color: #000 !important;
}
html[data-theme='dark'] .btn-dark .svg-icon {
    color: #000 !important;
}
html[data-theme='dark'] #kt_header_nav .menu-item .svg-icon {
    color: #fff !important;
}
html[data-theme='dark'] .bg-light {
    background: #3e414e !important;
}
html[data-theme='dark'] .page-link.active, 
html[data-theme='dark'] .active > .page-link {   
    background-color: #98ff65;
    color: #000 !important;
}
ngb-datepicker-navigation-select>.form-select {
    background-position: right 5px center !important;
    text-transform: capitalize;
}
ngb-datepicker.dropdown-menu {
    border: 1px solid rgba(0,0,0,.125);
}
.border-bottom-dashed-2 {
    border-bottom: 2px dashed #d5d5d5;
}
.nav-line-tabs .nav-item .nav-link.active, .nav-line-tabs .nav-item.show .nav-link, .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
    border-bottom: 1px solid #000000 !important;
}
.asi-stats {
    margin-left: 10px;
    color: #f44336;
}
.mb-0 .skeleton-loader {
    margin-bottom: 0 !important
}
.gap-50 {
    gap: 50px;
}
.gap-100 {
    gap: 100px;
}
.btn.btn-icon.btn-xs, .btn-group-xs > .btn.btn-icon {
    height: calc(1em + 1.4rem + 2px);
    width: calc(1em + 1.4rem + 2px);
}
.table th.actions {
    width: 150px;
}
.table.last-tr-border tfoot tr:last-child, .table.last-tr-border tbody tr:last-child {
     border-bottom: 1px dashed var(--kt-gray-300) !important;
}
.form-check-input.sm-check-input[type=radio] {
    height: 18px;
    background-size: 14px;
    max-width: 18px !important;
    padding: 8px !important;
}
.flex-1 {
    flex: 1 !important;
}
.mw-25p {
    max-width: 25%;
}
.modal.p-0 .modal-body {
    padding: 0;
}
.swal2-popup .swal2-actions {
    margin-left: 1.6rem !important;
    margin-right: 1.6rem !important;
}
.bi-bell-fill.text-danger,
.stepper-wrapper.cursor-pointer:hover .stepper-icon i.bi-bell-fill.text-danger {
    color: var(--kt-text-danger) !important;
}
.timeline .timeline-item:last-child .timeline-line {
    bottom: 0 !important
}
.calendar.rounded-right {
    background: #f6f8fa;
    border: 1px solid #E4E6EF !important;
    border-left: none !important;
}
.indicator-progress.select-inner {
    position: absolute;
    right: 40px;
    top: 7px;
}
.toolbar.development .text-white, .toolbar.development .breadcrumb-item .svg-icon {
    color: #181C32 !important
}
.toolbar.development .btn-sm.btn-ubiko {
    background: #85eb53 !important;
}
.password-eye {
    position: absolute;
    top: 28px;
    right: 20px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 7px;
}
.opacity-01 {
    opacity: .1;
}
.opacity-05 {
    opacity: .5;
}
.z-9 {
    z-index: 9;
}
.left-0 {
    left: 0;
}
.top-0 {
    top: 0;
}
.right-0 {
    right: 0;
}
.btn-inline-field {
    line-height: 1.5;
    padding: 0.5rem !important;
    width: auto !important;
    height: auto !important;
    max-height: 33px;
    display: flex;
}
.btn-inline-field span {
    display: flex;
    align-items: center;
}
.app-store-icon {
    max-height: 50px;
    width: auto;
}
.cal-month-view .cal-cell-top {
    min-height: auto;
    width: 100%;
}
.cal-month-view .cal-header .cal-cell {
    text-transform: capitalize;
}
.cycles-view-toggle {
    z-index: 10;
    display: flex;
    background: #bbff74;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 100px;
    right: 50px;
    font-weight: bold;
    border: 1px solid #83ff01;
    box-shadow: 0px 0px 10px 8px #ffffff;
}
.cycles-view-toggle:hover {
    background: #85eb53 !important;
}
.cycles-calendar-header {
    background: #f1f2f773;
    padding: 10px;
    margin-left: -2.25rem;
    margin-right: -2.25rem;
    width: auto !important;
    margin-top: -2rem;
}
.cycles .cal-month-view .cal-day-cell {
    min-height: 90px;
}
.cycles .cal-month-view .cal-day-number {
    font-size: 1rem;
    opacity: 1;
    margin-right: 0.5rem;
}
.cycles .cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1rem;
    font-weight: 800;
}
.cycles .cal-month-view .cal-day-cell.cal-out-month .cycle-day-session {
    display: none !important;
}
.cycles .cal-month-view .cal-day-badge {
    margin-left: 0.5rem;
    padding: 2px 4px;
}
.cycles .more-1 .session-data {
    height: auto !important;
}
.cycles .selecting .cal-month-view .cal-day-cell, .cycles .selecting .week-number-cell {
    cursor: copy;
}
.btn .badge {
    line-height: 1rem;
}
.session-alerts span i {
    font-size: 18px;
}
.session-item .session-item-content {
    margin-top: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.session-item.inactive .session-item-content {
    opacity: .2;
}
.session-item-actions {
    top: 0;
    left: 0;
    z-index: 1;    
    background: #ffffffd4;
    border: 1px solid #efefef;
}
.session-item:hover .session-item-actions {
    display: flex;
}
.cycles .table .col-player {
    min-width: 220px;
    width: 220px;
    max-width: 220px !important;
}
.session-item-header {   
    top: 0;
    left: 0;
    height: 25px;
    padding: 2px 4px;
    line-height: 25px;
}
.session-item-header i {
    font-size: 20px;
}
.stats-spinner span {
    margin-top: 200px;
}
.stats-spinner {
    background: #ffffffc2;
}
.sessions-actions a i.bi:before {
    vertical-align: middle;
}
.cal-cell {
    position: relative
}
.cell-selected {
    top: 3px;
    position: absolute;
    width: 100%;
    height: auto;
    display: none;
    transition: all 0.3s ease;
}
.cell-selected i {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding-top: 3px;
}
.cal-cell.cal-day-selected .cell-selected {
    display: flex;
}
.cycle-day-session .session-menu > .menu-link i {
    color: var(--kt-dark) !important;
}
/*.cycles .cal-cell-row .cal-cell > div {
    opacity: .5;
}
.cycles .cal-cell-row.current-week .cal-cell > div {
    opacity: 1;
}*/
.cycles .cal-cell-row.current-week .week-number-cell {
    background: #98ff65 !important;
}
.cal-month-view .cal-header {
    padding-left: 35px;
    background-color: #f5f6fa;
}
.cal-month-view .cal-cell-row .cal-cell:hover, .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #e4e8f7;
}
.card-sidebar .card-body {
    flex: unset;
}
.cycles .table th {
    max-width: calc((100% - 220px - 24rem) / 14);
    width: calc((100% - 220px - 24rem) / 14);
}
.cycles .table th label {
    max-width: 100%;
}
.cycles .table tfoot tr + tr {
    border-top: 1px solid #fff !important;
}
.cycles .btn-group > .btn:hover {
    z-index: inherit;
}

/* Custom for tooltip dark */
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: #1a1a31 !important;
}
.tooltip .tooltip-inner {    
    background: #1a1a31 !important;
    color: #fff !important;
}
.card .card-header.h-auto {
    min-height: unset !important;
}

html[data-theme=dark] #kt_header_nav .menu-item .bi {
    color: #fff !important;
}
html[data-theme=dark] .btn-ubiko span {
    color: var(--kt-dark) !important;
}
html[data-theme=dark] .form-control, html[data-theme=dark] .form-check-input,
html[data-theme=dark] .calendar.rounded-right {
    background: #3e414e !important;
    color: #fff !important;
    border-color: #3e414e !important;
}
html[data-theme=dark] .calendar.rounded-right {
    border-left: 1px solid #1a1a31 !important;
}
html[data-theme=dark] .cal-month-view .cal-header {
    padding-left: 35px;
    background-color: #1a1a31;
}
html[data-theme=dark] .cal-month-view .cal-days {
    background-color: #1a1a31;
}
html[data-theme=dark] .week-number-cell {   
    background: #1a1a31;
    border-color: #3f404d !important;
}
html[data-theme=dark] .cal-month-view .cal-day-cell,
html[data-theme=dark] .cal-month-view .cal-days {
    border-color: #3f404d !important;
}
html[data-theme=dark] .cal-month-view .cal-cell-row:hover {
    background-color: #3f404d;
}
html[data-theme=dark] .cycles .cal-cell-row.current-week .week-number-cell {
    color: #1a1a31;
}
html[data-theme=dark] .cal-month-view .cal-cell-row .cal-cell:hover, .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #1a1a31;
}
html[data-theme=dark] .cycles .cal-month-view .cal-today .cal-day-number,
html[data-theme=dark] .cycle-day-session .session-menu > .menu-link i {
    color: #1a1a31 !important;
}
html[data-theme=dark] .cycles .cal-has-events > div.h-100 {
    background: #fff;
    color: #1a1a31 !important;
}
html[data-theme=dark] .session-item-header {
    background: #fff !important;
}
html[data-theme=dark] .session-item-header i {
    color: #1a1a31 !important;
}
html[data-theme=dark] .cycles .table {
    color: #fff !important;
}
html[data-theme=dark] .cycles svg text,
html[data-theme=dark] .cycles .apexcharts-legend-text {
    fill: #fff !important;
}
html[data-theme=dark] .cycles-view-toggle {
    box-shadow: 0px 0px 10px 8px #1a1a31;
}
html[data-theme=dark] .section-title {
    background: #fff !important;
}
html[data-theme=dark] .section-title h3 {
    color: #1a1a31 !important;
}
html[data-theme=dark] .text-ubiko:not(.bi) {
    color: #1a1a31 !important;
}
html[data-theme=dark] button i {
    color: #1a1a31 !important;
}

.image-upload-optimized {
    margin-bottom: 65px;
}
.image-upload-optimized.confirm {
    position: absolute;
    width: 100%;
    height: auto;
    background: #fff;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0.625rem 0.625rem;
}
.fs-5rem {
    font-size: 5rem;
}

/* Comparative report */

.sparkline {
    position: relative; 
}
.comparative-report .text-danger {
    color: #cc0000 !important;
}
.comparative-report .bg-danger {
    background-color: #cc0000 !important;
}
.comparative-report .col-xl-2.flex-1 {
    max-width: 16.66666667%;
}
.summary-chart {
    max-height: 100px;
}
.comparative-report .progress {
    background-color: #888;
}
.comparative-report .table.gy-4 td, .comparative-report .table th,
.table.border-dark.gy-4 td, .table.border-dark th {
    border-right: 1px solid var(--kt-dark);
}
.comparative-report .table.gy-4 td:first-child, .comparative-report .table.gy-4 th:first-child,
.table.border-dark.gy-4 td:first-child, .table.border-dark.gy-4 th:first-child {
    border-left: 1px solid var(--kt-dark);
}
.comparative-report .table.gy-4 tr:last-child,
.table.border-dark.gy-4 tr:last-child {
    border-bottom: 1px solid var(--kt-dark) !important;
}
.comparative-report .table .progress {
    min-width: 35px;
    max-width: 125px;
}
.comparative-report .table.progress-background .progress {
    min-width: 100%;
    max-width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 1;
    border-radius: 0;
    background: transparent !important;
    opacity: 0.9;
    margin-top: 0 !important;
}
/*.comparative-report .table.progress-background .max_speed .progress {
    top: unset;
    bottom: 0;
    height: 5px !important;
}*/
.comparative-report .table.progress-background td {
    position: relative;
}
.comparative-report .table.progress-background td, .comparative-report .table.progress-background th {
    border-right: 1px solid var(--kt-dark);
}
.table.progress-background.table-row-gray-300 tr {
    border-bottom-color: var(--kt-dark);
}
.comparative-report .radar {
    height: 350px; 
    max-height: 350px; 
    min-height: 350px !important;
}
.comparative-report .radar svg {
    margin-top: -40px;
}
.comparative-report .tasks-donut {
    position: relative;
    margin-top: -15px;
}
.comparative-report .score {
    right: -5px;
}
.top-player {
    margin-top: -10px;
}
.card > .card-header.card-header-tabs-line {
    padding-top: 0!important;
    padding-bottom: 0!important;
    align-items: stretch;
}
.nav-link {
    color: var(--kt-dark) !important;
}
.nav-link.active, .nav-link:hover,
.nav-link.active .nav-icon i, .nav-link:hover .nav-icon i {
    color: var(--kt-info) !important;
}
.nav-dark .nav-link.active, .nav-dark .nav-link:hover,
.nav-dark .nav-link.active .nav-icon i, .nav-dark .nav-link:hover .nav-icon i {
    color: var(--kt-dark) !important;
}
.table tr:last-child:not(.pe-0), .table th:last-child:not(.pe-0), .table td:last-child:not(.pe-0) {
    padding-right: .75rem !important;
}
.table tr:first-child:not(.ps-0), .table th:first-child:not(.ps-0), .table td:first-child:not(.ps-0) {
    padding-left: .75rem !important;
}
.comparative-report .overflow-auto::-webkit-scrollbar-thumb {
    background: var(--kt-dark);
}
.comparative-report .overflow-auto::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: darkgray;
}
.comparative-report .overflow-auto::-webkit-scrollbar {
    width: 2rem;
    background: red;
    height: 10px;
}
.comparative-report .card-percent {
    min-height: 330px !important;
}
.comparative-report .card-summary > .card {
    min-height: 174px !important;
}
.comparative-report .card-tasks {
    min-height: 300px !important;
}
.comparative-report .card-tasks-intensity {
    min-height: 340px;
}
.comparative-report .bg-dark-primary {
    background: #930000 !important;
}
.comparative-report .indicator-progress {
    min-height: 80vh;
}
.cursor-help {
    cursor: help;
}
/*.comparative-report .card-table .card-body.overflow-auto::after {
    box-shadow: inset -14px 0px 11px 0px white;
    position: absolute;
    right: 0px;
    width: 50px;
    height: calc(100% - 20px);
    z-index: 2;
    top: 5px;
    content: "";
}*/
.nav-item .border-bottom-4 {
    border-style: solid !important;
}
.text-hover-white:hover i, .text-hover-white:hover span {
    color: #fff !important;
}
.comparative-report .card-toolbar .nav-tabs {
    margin-bottom: -1px;
}
.menu .menu-link:hover + .menu-sub-dropdown, .menu .menu-sub-dropdown:hover {
    display: flex !important;
}
.report-menu-options {
    left: -50px;
}
.comparative-report .major-green {
    background-color: #00e396 !important;
}
.top-card {
    min-height: 115px;
}
.comparative-report .heatmap {
    max-height: 350px;
    padding: 5px;
    max-width: 500px;
}
.comparative-report .row-2-cards > div > .card, .row-3-cards > div > .card, .row-4-cards > div > .card {
    min-height: 425px;
}
.comparative-report .row.g-xl-6 > div {
    --bs-gutter-x: 1.5rem;
}
.comparative-report .row.g-xl-6 {
    --bs-gutter-x: 0 !important;
}
.comparative-report .progress-bar.bg-gray-300 {
    background-color: var(--kt-gray-400) !important;
}
.bg-dark-medium {
    background: #a3a4b6 !important;
}

.acute-chronic .ng-select .ng-select-container, .rpe-wellness .ng-select .ng-select-container {
    min-height: 42px;
    border-color: #E4E6EF !important;
    border-radius: 0.475rem !important;
}
.acute-chronic .ng-select .ng-value-container, .rpe-wellness .ng-select .ng-value-container {
    padding: 0.5rem 1rem !important;
}
.acute-chronic .ng-select .ng-placeholder, .rpe-wellness .ng-select .ng-placeholder {
    padding: 0 !important;
    top: 10px !important
}
.acute-chronic .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.rpe-wellness .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: 1rem;
    margin-bottom: 0;
}
.mh-42px {
    max-height: 42px;
}
.acute-chronic .expanded .scroll-pagination {
    display: none
}
.acute-chronic .table-internal-change tr:not(.scroll-pagination) {
    display: none;
}
.acute-chronic .table-internal-change tr.avg {
    display: table-row !important;
}
.acute-chronic .table-internal-change tr:nth-child(1),
.acute-chronic .table-internal-change tr:nth-child(2),
.acute-chronic .table-internal-change tr:nth-child(3),
.acute-chronic .table-internal-change tr:nth-child(4),
.acute-chronic .table-internal-change tr:nth-child(5) {
    display: table-row;
}
.acute-chronic .table-internal-change.expanded tr {
    display: table-row;
}
.acute-chronic .table-internal-change a i.expand {
    display: inline-block;
}
.acute-chronic .table-internal-change.expanded a i.expand {
    display: none;
}
.acute-chronic .table-internal-change a i.collapse {
    display: none;
}
.acute-chronic .table-internal-change.expanded a i.collapse {
    display: inline-block;
}
.lastsession .card-body {
    min-height: 200px;
}
.lastsession .distances-chart {
    padding-left: 45px;
}
.notcomputed-loader {
    min-height: 115px;
}
.team-count {
    position: absolute;
    top: -18px;
    right: -15px;
    width: 18px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
}
.card-health-large-icon {
    position: absolute;
    font-size: 200px !important;
    right: -75px;
    top: -20px;
}
.card-health-large-icon.fa-user-injured {
    right: -50px;
}
.shield-45 {
    max-width: 45px;
    max-height: 45px;
}
.shield-25 {
    max-width: 25px;
    max-height: 25px;
}
.shield-22 {
    max-width: 22px;
    max-height: 22px;
}
.shield-20 {
    max-width: 20px;
    max-height: 20px;
}
.scroll-left {
    position: absolute;
    left: -40px;
    top: 45%;
    transform: translateY(-50%);
}

.scroll-right {
    position: absolute;
    right: -40px;
    top: 45%;
    transform: translateY(-50%);
}

.scroll-images {
    position: relative;
    width: 100%;
    padding: 40px 0px;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.symbol.symbol-30px {
    width: 30px;
    justify-content: center;
}
.symbol.symbol-25px {
    width: 25px;
    justify-content: center;
}
.symbol.symbol-30px > img {
    width: auto;
    height: 30px;
}
.symbol.symbol-25px > img {
    width: auto;
    height: 25px;
}
.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    opacity: 0;
    transition: all .2s ease-in-out;
}
.show-scrollTop {
    opacity: 1;
    transition: all .2s ease-in-out;
}
.training-planning .alert-low {
    background-color: #e0edfd;
}
.training-planning .alert-medium {
    background-color: #00E396;
}
.training-planning .alert-high {
    background-color: #FA4443;
}
.training-planning .cell-container {
    min-height: 46px;
}
.training-planning .alert-chart-0 {
    background-color: #8d918a;
}
.training-planning .alert-chart-1 {
    background-color: #D52329;
}
.training-planning .alert-chart-2 {
    background-color: #000000;
}
.alert-chart-week-rest {
    background: #000;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.overtraining .wellness-alert {
    color: #fff !important;
}

.rotate-left {
    transform: rotate(90deg);
}
.rotate-right {
    transform: rotate(-90deg);
}


@media (min-width: 1200px) {    
    .seven-cols .card-summary {
        width: calc(100% / 7);
        max-width: calc(100% / 7);
        flex: none;
    }
}

@media (max-width: 900px) {    
    .comparative-report .table td {
        border-right: 1px solid var(--kt-gray-300);
        border-left: 1px solid var(--kt-gray-300);
        border-top: 1px solid var(--kt-gray-300);
        border-bottom: 0;
    }
    .table-value-badge {
        flex-direction: row-reverse;
    }
    .comparative-report .table td > label + div {
        min-width: 30%;
    }
}

/* Horizontal loader */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #eaeaea;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
.overlay {
    background: #ffffffb3;
}
@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
}

.textarea-inline {
    min-height: 34px !important;
}
.nav-link.text-white.active, .nav-link.text-white:hover {
    color: #fff !important;
}
.topbar-header-image {
    position: absolute;
    right: -10px;
    top: -12px;
    opacity: 0.2;
    width: 98px;
}
.notification-bullet {
    position: absolute;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    top: -5px;
    right: -5px;
}
.ps-30px {
    padding-left: 30px;
}
.text-alert-info {
    color: #005f94 !important;
}
.topbar-alerts.syncs:after  {
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    left: 0;
    opacity: 0.1;
    background: url('/assets/images/docking-usb-negative.png') no-repeat;
    background-size: contain;
    background-position: right center;
}
.topbar-alerts.pushs:after  {
    content: "\f0f3";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 1;
    left: 0;
    opacity: 0.1;
    font-size: 75px;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.swal2-popup.syncs .table th, .swal2-popup.syncs .table td {
    white-space: nowrap;
}
.swal2-popup.syncs {
    max-width: 75vw !important;
    width: auto !important;
}
.menu.menu-sub.menu-sub-dropdown.min-w-200px {
    width: auto;
}
.icon-popup-zip {
    font-size: 40px;
}
.loading .icon-popup-zip.fa-file-circle-check,
.completed .icon-popup-zip.fa-circle-notch {
    display: none !important
}

.dropdown-toggle::after {
    font-size: 20px;
    vertical-align: middle !important;
}
.dropdown-toggle-split.separated {
    margin-left: 1px !important;
}
.device-selector {
    width: calc(100% - 450px);
    max-width: calc(100% - 400px);
}
.supply-users .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 4px 10px;
}
.supply-users.ng-select .ng-clear-wrapper,
.supply-users.ng-select .ng-select-container .ng-value-container {
    display: flex;
    align-items: center;
}
.supply-users.ng-select .ng-select-container .ng-value-container .ng-input>input {
    height: 26px;
}
.switch-user-icon.parent {
    font-size: 32px !important;
    color: #c3c3c3 !important;
}
.switch-user-icon.child {
    font-size: 18px !important;
    background: #fff;
    border-radius: 50%;
    color: #000 !important;
    border: 1px solid #e3e3e3;
}
.switch-user-icon.default {
    font-size: 32px !important;
    color: #c3c3c3 !important;
}
.password-input .form-control.is-invalid {
    background-image: none !important;
}

/* Animations */

.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}