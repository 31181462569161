/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// @import "./assets/sass/style";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
@import "./assets/sass/style.angular.scss";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
